import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../../vendor/tightenco/ziggy/dist/vue.m';
import VueKonva from "vue-konva";
import * as Sentry from '@sentry/vue';

import '../css/app.css';
import 'animate.css';

createInertiaApp({
    resolve: (name) => resolvePageComponent(`./Inertia/${name}.vue`, import.meta.glob('./Inertia/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        Sentry.init({
            app,
            environment: import.meta.env.VITE_APP_ENV,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

        app
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(VueKonva)
            .mount(el)
    },
    progress: {
        color: '#FFCE21',
    },
});
